/**
 * Runs the highlight.js on every `<pre><code>` block which is a child of the @param element.
 * If @param withLanguageClassOnly is set to `true`, only code blocks with explicitly set
 * language are highlighted (the language is set by adding a class to the `<code>` element,
 * e.g. "language-python").
 */
export async function runHighlightjs(element: HTMLElement, withLanguageClassOnly = false): Promise<void> {
    if (!element) return;

    let hljs = (await import('highlight.js')).default;

    const codeElements = element.querySelectorAll('pre code');
    for (let i = 0; i < codeElements.length; i += 1) {
        // eslint-disable-next-line no-continue
        if (withLanguageClassOnly && !codeElements[i].className.includes('language')) continue;

        hljs.highlightElement(codeElements[i] as HTMLElement);
    }
}

/** All the `<pre><code>` blocks will be highlighted whenever the component gets updated. */
export const hljsDirective = {
    updated: (element: HTMLElement) => runHighlightjs(element),
    mounted: (element: HTMLElement) => runHighlightjs(element),
};
