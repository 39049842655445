import { AxiosError } from 'axios';
import { Course, CourseState, Scoreboard } from '@/types';
import apiService from './ApiService';

export enum Role {
    STUDENT = 'student', TEACHER = 'teacher', TESTER = 'tester', OTHER = 'other'
}

type RegisterIntoCourseParams = {
    contestantRole: Role;
    maturita: number;
    teacher: string,
    school: {
        address: string,
        name: string,
        city: string,
    }
};

export function isActive(course: Course): boolean {
    return course.state === CourseState.ACTIVE || course.state === CourseState.ACTIVE_FROZEN;
}

class CourseService {

    async getAllCourses(): Promise<Course[] | null> {
        return apiService.get('courses');
    }

    async getAllCoursesFromYear(year: number): Promise<Course[] | null> {
        return apiService.get('courses', { params: { year } });
    }

    async getCourse(courseId: number): Promise<Course | null> {
        return apiService.get(`courses/${courseId}`);
    }

    async registerIntoCourse(courseId: number, {
        contestantRole, maturita, teacher, school,
    }: RegisterIntoCourseParams, errorHandler?: (error: AxiosError) => boolean): Promise<number | null> {
        const payload = {
            contestant_role: contestantRole,
            maturita,
            teacher,
            school,
        };

        return apiService.post(`courses/${courseId}/register`, payload, undefined, errorHandler);
    }

    async getLastActiveCourse(): Promise<Course | null> {
        const courses = await this.getAllCourses();
        if (courses === null) return null;
        const activeCourses = courses.filter(isActive);
        if (activeCourses.length === 0) return null;
        return activeCourses.reduce((bestOne, current) => {
            if (bestOne && current.start_time > bestOne.start_time) {
                return current;
            }
            return bestOne;
        });
    }

    async getScoreboard(courseId: number, overrideFreeze = false): Promise<Scoreboard | null> {
        return apiService.get(`courses/${courseId}/scoreboard?overrideFreeze=${overrideFreeze}`);
    }

    /**
     * Gets results only for currently logged-in user. If no user is logged in or current user is not in course,
     * returns `null`.
     */
    async getScoreboardSingle(courseId: number): Promise<Scoreboard | null> {
        if (!apiService.isLoggedInNow()) {
            return Promise.resolve(null);
        }
        return apiService.get(`courses/${courseId}/scoreboard-single`, undefined, (error: AxiosError) => {
            // ignore some errors and just return `null` as the result of request
            if (error?.response?.data === 'NOT_IN_COURSE') return true;
            return false;
        });
    }
}

export default new CourseService();
