<template>
    <div>
        <b-button
            v-if="!$store.state.isLoggedIn"
            variant="outline-light"
            @click="showLoginModal = true"
        >
            Přihlášení
        </b-button>
        <b-button v-if="$store.state.isLoggedIn" variant="outline-light" @click="logout">
            Odhlásit se
        </b-button>

        <b-modal
            id="login-modal"
            v-model="showLoginModal"
            title="Přihlášení"
            hide-footer
        >
            <b-form @submit="login">
                <b-form-group
                    label-cols="6"
                    content-cols="6"
                    label="E-mail"
                    label-for="login-email"
                    class="mb-2"
                >
                    <b-form-input id="login-email" v-model="currentEmail" required type="email"/>
                </b-form-group>
                <b-form-group
                    label-cols="6"
                    content-cols="6"
                    label="Heslo"
                    label-for="login-password"
                    class="mb-2"
                >
                    <b-form-input
                        id="login-password"
                        v-model="currentPassword"
                        required
                        type="password"
                    />
                </b-form-group>
                <b-form-row v-if="wrongPassword" class="form-group">
                    <b-col class="wrong-password">
                        Špatný email nebo heslo.
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col>
                        <b-button type="submit" :disabled="requestInProgress === true">
                            Přihlásit se
                        </b-button>
                    </b-col>
                    <b-col class="col-form-label">
                        <Loader v-if="requestInProgress" class="smallLoader"/>
                        <router-link v-else to="/reset-password" @click="close()">
                            Zapomněl(a) jsi heslo?
                        </router-link>
                    </b-col>
                </b-form-row>
            </b-form>
        </b-modal>
    </div>
</template>

<script lang="ts">
import { AxiosError } from 'axios';
import authService from '@/api/AuthService';
import { defineComponent, reactive, watchEffect } from 'vue';
import Loader from '@/components/ui/Loader.vue';

export default defineComponent({
    components: {
        Loader,
    },
    setup() {
        const data: {
            currentEmail: string,
            currentPassword: string,
            showLoginModal: boolean,
            wrongPassword: boolean,
            requestInProgress: boolean
        } = reactive({
            currentEmail: '',
            currentPassword: '',
            showLoginModal: false,
            wrongPassword: false,
            requestInProgress: false,
        });

        watchEffect(async() => {
            // Reset the window when it is closed
            if (!data.showLoginModal) {
                data.wrongPassword = false;
                data.currentEmail = '';
                data.currentPassword = '';
            }
        });
        return data;
    },
    methods: {
        async login(event: Event): Promise<void> {
            event.preventDefault();
            if (this.requestInProgress) return;

            this.requestInProgress = true;
            this.wrongPassword = false;
            const success = await authService.login(
                { email: this.currentEmail, password: this.currentPassword },
                this.handleLoginError,
            );
            if (success) {
                this.showLoginModal = false;
            }
            this.requestInProgress = false;
        },
        handleLoginError(error: AxiosError): boolean {
            if (error?.response?.status === 401) {
                this.wrongPassword = true;
                return true;
            }
            return false;
        },
        async logout(): Promise<void> {
            await authService.logout();
        },
        close(): void {
            this.showLoginModal = false;
        },
    },
});

</script>
<style scoped>
.wrong-password {
    color: red;
}

.smallLoader {
    top: -30px;
    height: 20px;
}

.passwordReset {
    padding-top: 10px;
}
</style>
