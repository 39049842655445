<template>
    <b-form-group class="root">
        <label :for="id">{{ label }}</label>
        <RequiredStar :required="required"/>

        <b-form-input
            :id="id"
            v-model="value"
            :type="type"
            :placeholder="placeholder"
            :required="required"
            @input="updateValidity"
        />

        <b-form-invalid-feedback>
            {{ validity }}
        </b-form-invalid-feedback>
    </b-form-group>
</template>

<script lang="ts" setup>
import { computed, ref, type PropType, watchEffect, onMounted } from 'vue';
import type { InputType } from 'bootstrap-vue-next';

const props = defineProps({
    modelValue: {
        type: String,
        default: '',
    },
    type: { type: String as PropType<InputType>, required: true },
    placeholder: { type: String, default: '' },
    required: { type: Boolean },
    label: { type: String, required: true },
    customValidity: { type: String, default: '' },
});

const emit = defineEmits(['update:modelValue']);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit('update:modelValue', newValue);
    },
});

const id = crypto.randomUUID();
const validity = ref('');

function updateValidity() {
    const element = document.getElementById(id) as HTMLInputElement | null;
    validity.value = element?.validationMessage ?? '';
}

onMounted(() => {
    watchEffect(() => {
        const element = document.getElementById(id) as HTMLInputElement | null;
        element?.setCustomValidity(props.customValidity);

        updateValidity();
    });
});
</script>

<style scoped>
label {
    font-weight: bold;
    margin-bottom: 0;
}

.root {
    margin: 8px;
}
</style>
