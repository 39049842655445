<template>
    <b-modal v-model="$store.state.registrationModalOpen" size="lg" title="Registrace" hide-footer>
        <b-alert v-if="userRegistrationComplete" variant="success" :model-value="true">
            <p>Registrace proběhla úspěšně. Na email jsme Ti poslali ověřovací kód.</p>
            <p class="no-bottom-margin">
                Po ověření emailu se budeš moci přihlásit a řešit úlohy.
            </p>
        </b-alert>
        <b-form v-else :validated="validated" @submit.prevent="registerUser()">
            <p class="no-bottom-margin">
                Pole označená
                <RequiredStar :required="true"/> jsou povinná.
            </p>
            <section>
                <div class="split-line">
                    <Field v-model="name" name="name" label="Jméno" type="text" required/>
                    <Field v-model="surname" name="surname" label="Příjmení" type="text" required/>
                </div>
                <Field
                    v-model="email"
                    name="email"
                    label="Email"
                    type="email"
                    required
                    :custom-validity="emailError"
                />
                <div class="split-line">
                    <Field
                        v-model="password"
                        name="password"
                        label="Heslo"
                        type="password"
                        required
                        :custom-validity="passwordError"
                    />
                    <Field
                        v-model="passwordAgain"
                        name="password-again"
                        label="Heslo znovu"
                        type="password"
                        required
                        :custom-validity="passwordAgainError"
                    />
                </div>
            </section>

            <section>
                <h3>Několik zvědavých dotazů ...</h3>
                <Field v-model="knowsFrom" label="Odkud nás znáš?" type="text"/>
                <Field v-model="otherGigs" label="Jaké jiné soutěže řešíš?" type="text"/>
            </section>

            <section>
                <h3>Zpracování osobních údajů</h3>
                <CheckboxField v-model="gdpr" required :custom-validity="gdprError">
                    Souhlasím s podmínkami zpracování osobních údajů.
                </CheckboxField>
                <PersonalDataTerms/>
                <CheckboxField v-model="newsletter">
                    Chci na email dostávat informace o další super akcích od Matfyzu.
                </CheckboxField>
            </section>

            <div class="register-btn-wrapper">
                <b-button variant="primary" class="register-button" type="submit" @click="setValidated()">
                    Zaregistrovat se
                </b-button>
            </div>
        </b-form>
    </b-modal>
</template>

<script lang="ts">
import { AxiosError } from 'axios';
import authService from '@/api/AuthService';
import Field from '@/components/forms/Field.vue';
import CheckboxField from '@/components/forms/CheckboxField.vue';
import PersonalDataTerms from '@/components/auth/PersonalDataTerms.vue';
import RequiredStar from '@/components/forms/RequiredStar.vue';
import { defineComponent, watch } from 'vue';

export default defineComponent({
    components: {
        Field,
        CheckboxField,
        PersonalDataTerms,
        RequiredStar,
    },
    data() {
        return {
            validated: false,
            userRegistrationComplete: false,

            // User registration
            email: '',
            password: '',
            name: '',
            surname: '',
            passwordAgain: '',
            knowsFrom: '',
            otherGigs: '',
            gdpr: false,
            newsletter: false,

            // One time errors
            emailError: '',
        };
    },
    computed: {
        passwordError() {
            if (this.password.length < 6) {
                return 'Heslo je moc krátké.';
            }

            return '';
        },
        passwordAgainError() {
            if (this.password !== this.passwordAgain) {
                return 'Hesla se neshodují.';
            }

            return '';
        },
        gdprError() {
            if (!this.gdpr) {
                return 'Pro účast v soutěži musíte souhlasit s našimi podmínkami.';
            }

            return '';
        },
    },
    created(): void {
        this.$store.subscribe((mutation) => {
            if (mutation.type === 'logout') {
                this.userRegistrationComplete = false;
                this.clearFields();
            }
        });

        watch(() => this.email, () => {
            this.emailError = '';
        });

        watch(() => this.$store.state.registrationModalOpen, () => {
            this.validated = false;
        });
    },
    methods: {
        setValidated(): void {
            this.validated = true;
        },
        clearFields(): void {
            this.email = '';
            this.password = '';
            this.name = '';
            this.surname = '';
            this.passwordAgain = '';
            this.knowsFrom = '';
            this.otherGigs = '';
            this.gdpr = false;
            this.newsletter = false;
        },
        async registerUser(): Promise<void> {
            try {
                if (!this.$store.state.isLoggedIn) {
                    const registerResult = await authService.register(
                        {
                            email: this.email,
                            password: this.password,
                            firstName: this.name,
                            lastName: this.surname,
                            otherGigs: this.otherGigs,
                            mffSpam: this.newsletter,
                            knowsFrom: this.knowsFrom,
                        },
                        this.handleErrorAuthRegister,
                    );

                    if (registerResult === null) {
                        // error in the request
                        this.$forceUpdate(); // redraw the validation messages
                        return;
                    }
                }
            } catch (error) {
                console.error(error);
            }
            this.userRegistrationComplete = true;
        },
        handleErrorAuthRegister(error: AxiosError): boolean {
            if (error?.response?.data === 'EMAIL_INVALID') {
                this.emailError = 'Neplatný email.';
                return true;
            }
            if (error?.response?.data === 'EMAIL_OCCUPIED') {
                this.emailError = 'Uživatel s tímto emailem už existuje.';
                return true;
            }
            return false;
        },
    },
});
</script>

<style scoped>
form {
    padding: 8px;
}

h3 {
    color: #ff0052;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 8px;
}

.no-bottom-margin {
    margin-bottom: 0;
}

.split-line {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.split-line>* {
    flex-grow: 1;
}

.register-btn-wrapper {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}
</style>
