<template>
    <section>
        <h3>
            Nastavení uživatele
        </h3>

        <BFormCheckbox v-model="spamSwitchChecked" switch :disabled="loading">
            Chci na email dostávat informace o další super akcích od Matfyzu.
            <Loader v-if="loading === true" small/>
        </BFormCheckbox>
    </section>
</template>

<script setup lang="ts">
import userService from '@/api/UserService';
import snackbarService from '@/api/SnackbarService';
import { User } from '@/types';
import { ref, watchEffect, watch } from 'vue';

const loading = ref(true);
const spamSwitchChecked = ref(false);
let lastSavedSpamChecked = false;
const user = ref<User | null>(null);

watch(spamSwitchChecked, async() => {
    if (lastSavedSpamChecked === spamSwitchChecked.value) // only call API if value is changed by user
        return;
    if (loading.value) // prevent multiple requests simultaneously
        return;
    if (user.value === null) // prevent request when not loaded correctly
        return;

    loading.value = true;

    const success = await userService.postUserSpam(user.value.id, spamSwitchChecked.value);
    if (success) {
        lastSavedSpamChecked = spamSwitchChecked.value;
        snackbarService.showSuccess('Nastavení uloženo.');
    }
    else {
        spamSwitchChecked.value = lastSavedSpamChecked; // reset checkbox to last correctly saved value
        snackbarService.showError('Chyba při ukládání nastavení.');
    }
    loading.value = false;
});

async function load(): Promise<void> {
    const currentUser = await userService.getCurrentUser();
    if (currentUser === null) {
        snackbarService.showError('Chyba při načítání.');
        return;
    }

    user.value = currentUser;
    lastSavedSpamChecked = currentUser.mff_spam;
    spamSwitchChecked.value = currentUser.mff_spam;
    loading.value = false;
};
watchEffect(load);


</script>

<style scoped>

</style>
