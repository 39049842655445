import { createApp } from 'vue';
import {
    createRouter, createWebHistory, RouteRecordRaw,
} from 'vue-router';
import '@/styles/bootstrap.scss';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import 'katex/dist/katex.min.css';
import 'highlight.js/styles/default.css';
import '@fontsource/titillium-web';
import App from '@/App.vue';
import NotFoundPage from '@/components/pages/NotFoundPage.vue';
import { DesignItem, LandingTitle } from '@/types';
import * as Sentry from '@sentry/vue';
import { getStromecek } from '@/config/web_config';
import VuePictureSwipe from 'vue3-picture-swipe';
import '@/styles/styles.scss';
import { generateRoutesFromMenu, generateMenuFrom } from '@/menu';
import { store } from './store';
import { katexDirective } from '@/katex-run';
import { hljsDirective } from './hljs-run';
import webService from '@/api/WebService';
import authService from './api/AuthService';

Promise.all([
    getStromecek(),
    webService.getCurrentLandingTitle(),
    authService.refreshLogin(),
])
    .then(([[stromecek, extraRoutes], landingTitle, _loginRefreshSuccessful]:
        [[DesignItem[], RouteRecordRaw[]], LandingTitle | null, boolean]) => {
        const menu = generateMenuFrom(stromecek);

        // craate App
        const app = createApp(App, { menuStromecek: menu, landingTitle });

        // initialize Vuex Store
        app.use(store);

        // initialize other libraries
        app.component('VuePictureSwipe', VuePictureSwipe);
        app.directive('katex', katexDirective);
        app.directive('syntaxHighlight', hljsDirective);

        // initialize Router
        const routes: RouteRecordRaw[] = generateRoutesFromMenu(menu);
        routes.push(...extraRoutes);
        routes.push({ path: '/', redirect: { path: '/uvod/' } });
        routes.push({
            path: '/:catchAll(.*)',
            component: NotFoundPage,
            meta: {
                title: '404 Stránka nenalezena',
                headlineTop: 'Chyba 404',
                headlineBottom: 'Stránka nenalezena',
            },
        });

        const router = createRouter({
            history: createWebHistory(),
            routes,
        });

        router.beforeEach((to: any, _: any, next: any) => {
            if (to == null || to.meta == null || to.meta.title == null || to.path.startsWith('/uvod/')) {
                document.title = 'Kasiopea';
            } else if (to.meta.pageTitle) {
                document.title = `${to.meta.pageTitle} – Kasiopea`;
            } else {
                document.title = `${to.meta.title} – Kasiopea`;
            }
            next();
        });

        router.beforeEach((to: any, from: any, next: any) => {
            if (!to.fullPath.endsWith('/')) {
                next(`${to.fullPath}/`);
            } else {
                next();
            }
        });

        app.use(router);

        // initialize Sentry
        if (import.meta.env.VITE_ENABLE_SENTRY === 'true') {
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN,
                integrations: [
                    new Sentry.BrowserTracing({
                        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    }),
                    new Sentry.Replay(),
                ],
                tracesSampleRate: 1.0,
                replaysOnErrorSampleRate: 1.0,
                release: import.meta.env.VITE_SENTRY_RELEASE,
            });

            Sentry.addBreadcrumb({
                category: 'Vue',
                message: 'Vue initialized',
            });

            console.log('Sentry initialized');
        } else {
            console.log('Sentry disabled');
        }

        // mount App
        app.mount('#app');
    })
    .catch((error: Error) => {
        console.error(error);
    });
