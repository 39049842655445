import { LandingTitle } from '@/types';
import apiService from './ApiService';

class WebService {
    async getCurrentLandingTitle(): Promise<LandingTitle | null> {
        return apiService.get('web/current_landing_title');
    }
}

export default new WebService();
