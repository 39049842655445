import { AxiosError, AxiosRequestConfig } from 'axios';
import jwtService from '@/api/JwtService';
import snackbarService from '@/api/SnackbarService';
import { LoginResponse } from '@/types';
import { getApiErrorLocalization } from './ApiUtils';
import axios from 'axios';

export const AuthHeaderName = 'X-KASIOPEA-AUTH-TOKEN';

function isAxiosError(error: unknown): error is AxiosError {
    return error instanceof Error && !!(error as AxiosError).isAxiosError;
}

/**
 * **DO NOT USE** outside of API services
 *
 * If you need to call an API endpoint that doesn't have service function: create one
 */
class ApiService {

    constructor() {
        axios.defaults.withCredentials = true;
        axios.defaults.baseURL = '/'; // proxy to backend set in `vue.config.js`
        // eslint-disable-next-line max-len
        axios.defaults.headers.common.accept = 'application/json;charset=utf-8, text/plain;charset=utf-8, text/html;charset=utf-8';
    }

    isLoggedInNow() : boolean {
        return jwtService.isLoggedInNow();
    }

    /** It is need call after user is authorized */
    setHeaderAndSaveToken(accessToken: string): void {
        axios.defaults.headers.common[AuthHeaderName] = `Bearer ${accessToken}`;
        jwtService.saveToken(accessToken);
    }

    unsetHeader(): void {
        axios.defaults.headers.common[AuthHeaderName] = '';
    }

    /**
     * Sends GET request to the API.
     *
     * The `errorHandler` can be used to handle server responces with a status code that falls out of the range of 2xx.
     * This function should return `true` if the error was handled. Otherwise, default error handling is used (see
     * `this.handleError`). Use the `error.response` field to access the response.
     * @param allowRefresh - Normally there is attempt to refresh access token and retry the request. Setting this to
     * `false` disables this behaviour
     */
    async get<R = unknown>(
        resource: string,
        config?: AxiosRequestConfig,
        errorHandler?: (error: AxiosError) => boolean,
        allowRefresh = true,
    ): Promise<R | null> {
        try {
            const { data } = await axios.get(`api/${resource}`, config)
                .catch(async(err) => {
                    if (allowRefresh && this.shouldRefresh(err)) {
                        const result = await this.refresh();
                        // If the refresh was successful, retry the request
                        if (result) {
                            return axios.get(`api/${resource}`, config);
                        }
                    }
                    // Otherwise, pass the error to the catch below
                    throw err;
                });
            return data;
        } catch (error) {
            // first try the supplied errorHandler
            this.handleError(error, errorHandler);
            return null;
        }
    }

    /**
     * Sends POST request to the API.
     *
     * The `errorHandler` can be used to handle server responces with a status code that falls out of the range of 2xx.
     * This function should return `true` if the error was handled. Otherwise, default error handling is used (see
     * this.handleError). Use the `error.response` field to access the response.
     * @param allowRefresh - Normally there is attempt to refresh access token and retry the request. Setting this to
     * `false` disables this behaviour
     */
    async post<R = unknown>(
        resource: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params?: any,
        config?: AxiosRequestConfig,
        errorHandler?: (error: AxiosError) => boolean,
        allowRefresh = true,
    ): Promise<R | null> {
        try {
            const { data } = await axios.post(`api/${resource}`, params, config)
                .catch(async(err) => {
                    if (allowRefresh && this.shouldRefresh(err)) {
                        const result = await this.refresh();
                        // If the refresh was successful, retry the request
                        if (result) {
                            return axios.post(`api/${resource}`, params, config);
                        }
                    }
                    // Otherwise, pass the error to the catch below
                    throw err;
                });
            return data;
        } catch (error) {
            // first try the supplied errorHandler
            this.handleError(error, errorHandler);
            return null;
        }
    }

    /**
     * Backend errors related to auth:
     *  - 403, WRONG_TOKEN  = token is wrong (e.g., expired) -> it makes sense to refresh
     *  - 401, UNAUTHORIZED = user not logged in (no token) -> no need to refresh (if we had a valid refresh token,
     *                        the refresh would have occured when page loaded)
     *  - 401, <empty>      = token is valid, but has unsufficient claim -> this should not happen as tokens with a
     *                        different claim are used only by `verify` and `change_password` and those don't refresh
     *
     * @param returnedError response to the Axios request
     * @returns `true` if it makes sense to attempt to refresh the access token
     */
    shouldRefresh(returnedError: AxiosError): boolean {
        return this.isLoggedInNow()
            && isAxiosError(returnedError)
            && returnedError.response !== undefined
            && returnedError.response.status === 403
            && returnedError.response.data === 'WRONG_TOKEN';
    }

    /** Handles errors from API requests (used in both GET and POST).
     *  Returns `true` if the error was handled.
     */
    private handleError(error: AxiosError<string>, errorHandler?: (error: AxiosError<string>) => boolean): void {
        // call the custom errorHandler
        if (typeof errorHandler === 'function') {
            if (errorHandler(error)) {
                // the error was handled by the error handler
                return;
            }
        }

        // default error handling
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            const { response } = error;
            if (response.status === 403 && response.data === 'WRONG_TOKEN') {
                snackbarService.showError('Zkuste se znovu přihlásit.', 'Nepodařilo se obnovit přihlášení');
            } else if (response.status === 401 && response.data === 'UNAUTHORIZED') {
                snackbarService.showError('Zkuste se přihlásit.', 'Nejste přihlášeni');
            } else if (response.status >= 500) {
                snackbarService.showError(getApiErrorLocalization(response.data, 'Neočekávaná chyba serveru.'),
                    'Chyba na straně serveru');
            } else {
                snackbarService.showError(getApiErrorLocalization(response.data), `Chyba`);
            }
        } else if (error.request) {
            // The request was made but no response was received
            snackbarService.showError('Zkuste to znovu později.', 'Chyba komunikace se serverem');
        } else {
            // Something happened in setting up the request that triggered an Error
            throw error;
        }
    }

    /** Handles refreshing of the access tokens. Returns true if the refresh was successful.
     *
     *  I'm not completely happy with this solution as I think this belongs to the AuthService.
     *  But that creates a circular dependency, so it is not possible. :/
     */
    async refresh(): Promise<boolean> {
        // Try to refresh the token. Ignore all errors -> they will result in `data == null`.
        const data: LoginResponse | null = await this.post('auth/refresh', undefined, {
            xsrfHeaderName: 'X-XSRF-KASIOPEA-AUTH',
            xsrfCookieName: 'kasiopea-auth-xsrf-token',
            withCredentials: true,
        }, () => true, false);
        if (data == null) {
            jwtService.deleteToken();
            this.unsetHeader();
            return false;
        }
        // save the token into local storage
        this.setHeaderAndSaveToken(data.access_token);
        return true;
    }
}

export default new ApiService();

export const HeaderCacheControlPublicTwoHours = { 'Cache-Control': 'public, max-age=7200' }; // 7200 seconds = 2 hours
