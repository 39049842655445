import { RouteRecordRaw } from 'vue-router';
import { AccessGroup, Course, DesignItem } from '@/types';
import courseService from '@/api/CourseService';
import { generateArchiv, generateSoutez } from '../menu';
import { assetsImageURL } from './images_list';
import UserPage from '@/components/pages/UserPage.vue';

const ErrorPage = () => import('@/components/pages/ErrorPage.vue');
const EmptyPage = () => import('@/components/pages/EmptyPage.vue');
const VerifyEmailPage = () => import('@/components/pages/VerifyEmailPage.vue');
const PasswordResetPage = () => import('@/components/pages/PasswordResetPage.vue');
const NewPasswordPage = () => import('@/components/pages/NewPasswordPage.vue');
const ArchivPage = () => import('@/components/pages/ArchivPage.vue');
const UvodPage = () => import('@/components/pages/UvodPage.vue');
const OSoutezi = () => import('@/components/pages/OSoutezi.vue');
const Kontakt = () => import('@/components/pages/Kontakt.vue');
const Organizatori = () => import('@/components/pages/Organizatori.vue');
const DalsiAkce = () => import('@/components/pages/DalsiAkce.vue');
const Blog = () => import('@/components/pages/Blog.vue');
const KarelTesar = () => import('@/components/pages/blog/KarelTesar.vue');
const JanaBatoryova = () => import('@/components/pages/blog/JanaBatoryova.vue');
const VaclavVolhejn = () => import('@/components/pages/blog/VaclavVolhejn.vue');
const RulesPage = () => import('@/components/pages/RulesPage.vue');
const JakResit = () => import('@/components/pages/JakResit.vue');
const ProUcitele = () => import('@/components/pages/ProUcitele.vue');
const JakNaTo = () => import('@/components/pages/tutorialy/JakNaTo.vue');
const VstupVystup = () => import('@/components/pages/tutorialy/VstupVystup.vue');
const Modulo = () => import('@/components/pages/tutorialy/Modulo.vue');
const Retezce = () => import('@/components/pages/tutorialy/Retezce.vue');
const DruhyUloh = () => import('@/components/pages/tutorialy/DruhyUloh.vue');
const Trideni = () => import('@/components/pages/tutorialy/Trideni.vue');
const Slozitost = () => import('@/components/pages/tutorialy/Slozitost.vue');
const Grafy = () => import('@/components/pages/tutorialy/Grafy.vue');
const DynamickeProgramovani = () => import('@/components/pages/tutorialy/DynamickeProgramovani.vue');
const PrehledJazyku = () => import('@/components/pages/tutorialy/PrehledPrgJazyku.vue');
const OrgPage = () => import('@/components/pages/org/OrgPage.vue');
const UnauthorizedPage = () => import('@/components/pages/org/UnauthorizedPage.vue');
const UsersPage = () => import('@/components/pages/org/UsersPage.vue');
const UserDetailsPage = () => import('@/components/pages/org/UserDetailsPage.vue');


async function getStromecek(): Promise<[DesignItem[], RouteRecordRaw[]]> {
    const courses: Course[] = (await courseService.getAllCourses()) || [];
    const now: Date = new Date();
    const competitionCourse: Course | undefined = courses.find(
        (course) => new Date(course.start_time) < now && new Date(course.unfreeze_time) > now,
    );
    const finishedCourses = courses.filter(
        (course) => new Date(course.unfreeze_time) <= now,
    );
    const lastPastCourse = finishedCourses.length > 0
        ? finishedCourses.sort((a, b) => {
            if (a.unfreeze_time < b.unfreeze_time) return -1;
            if (a.unfreeze_time > b.unfreeze_time) return 1;
            return 0;
        }).pop() : undefined;

    const promised = await Promise.all([
        generateSoutez(competitionCourse, lastPastCourse),
        generateArchiv(courses),
    ]);
    const stromecek: Array<DesignItem> = [
        {
            pathName: 'uvod',
            group: AccessGroup.All,
            page: UvodPage,
            meta: {
                title: 'Úvod', headerStyle: 'landing', noSubMenu: true,
            },
            subpages: [],
        },
        promised[0][0], // dynamically generated competition
        {
            pathName: 'archiv',
            group: AccessGroup.All,
            page: EmptyPage,
            meta: { title: 'Archiv úloh', headlineTop: 'Soutěž', headlineBottom: 'Kasiopea' },
            redirect: '/archiv/uvod',
            subpages: [
                {
                    pathName: 'uvod',
                    group: AccessGroup.All,
                    page: ArchivPage,
                    meta: {
                        title: 'Úvod',
                        headlineTop: 'Vyzkoušej si úlohy',
                        headlineBottom: 'z minulého ročníku!',
                    },
                    subpages: [],
                },
                ...promised[1][0], // dynamically generated archive
            ],
        }, {
            pathName: 'onas',
            redirect: 'onas/osoutezi',
            group: AccessGroup.All,
            page: ErrorPage, // unused due to redirect
            meta: { title: 'O nás', headlineTop: 'Co je to za', headlineBottom: 'soutěž?' },
            subpages: [{
                pathName: 'osoutezi',
                group: AccessGroup.All,
                page: OSoutezi,
                meta: { title: 'O soutěži', headlineTop: 'Co je to za', headlineBottom: 'soutěž?' },
                subpages: [],
            }, {
                pathName: 'kontakt',
                group: AccessGroup.All,
                page: Kontakt,
                subpages: [],
                meta: { title: 'Kontakt', headlineTop: 'Kontaktní', headlineBottom: 'údaje' },
            }, {
                pathName: 'organizatori',
                group: AccessGroup.All,
                page: Organizatori,
                subpages: [],
                meta: { title: 'Organizátoři', headlineTop: 'Kdo to tu', headlineBottom: 'organizuje?' },
            }, {
                pathName: 'dalsi_akce',
                group: AccessGroup.All,
                page: DalsiAkce,
                meta: { title: 'Další akce', headlineTop: 'Co řešit', headlineBottom: 'po Kasiopee?' },
                subpages: [],
            }, {
                pathName: 'blog',
                group: AccessGroup.All,
                page: Blog,
                meta: { title: 'Blog', headlineTop: 'Kasiopea', headlineBottom: 'BLOG' },
                subpages: [{
                    pathName: 'karel_tesar',
                    group: AccessGroup.All,
                    page: KarelTesar,
                    meta: { title: 'Karel Tesař', headlineTop: 'BLOG', headlineBottom: 'Karel Tesař' },
                    subpages: [],
                }, {
                    pathName: 'jana_batoryova',
                    group: AccessGroup.All,
                    page: JanaBatoryova,
                    subpages: [],
                    meta: { title: 'Jana Bátoryová', headlineTop: 'BLOG', headlineBottom: 'Jana Bátoryová' },
                }, {
                    pathName: 'vaclav_volhejn',
                    group: AccessGroup.All,
                    page: VaclavVolhejn,
                    subpages: [],
                    meta: { title: 'Václav Volhejn', headlineTop: 'BLOG', headlineBottom: 'Václav Volhejn' },
                }],
            }],
        }, {
            pathName: 'pravidla',
            group: AccessGroup.All,
            page: RulesPage,
            meta: { title: 'Pravidla', headlineTop: 'Jak vlastně bude soutěž', headlineBottom: 'probíhat?' },
            subpages: [],
        }, {
            pathName: 'tipytriky',
            redirect: 'tipytriky/otazky',
            group: AccessGroup.All,
            page: ErrorPage, // unused due to redirect
            meta: { title: 'Jak řešit', headlineTop: '', headlineBottom: '' },
            // redirects for subpages are specified below
            subpages: [{
                pathName: 'otazky',
                group: AccessGroup.All,
                page: JakResit,
                meta: { title: 'Otázky a odpovědi', headlineTop: 'Otázky', headlineBottom: 'a odpovědi' },
                subpages: [],
            }, {
                pathName: 'prehled_jazyku',
                group: AccessGroup.All,
                page: PrehledJazyku,
                meta: {
                    title: 'Programovací jazyky',
                    headlineTop: 'Stručný přehled',
                    headlineBottom: 'programovacích jazyků',
                },
                subpages: [],
            }, {
                pathName: 'druhy_uloh',
                group: AccessGroup.All,
                page: DruhyUloh,
                meta: { title: 'Co čekat od úloh?', headlineTop: 'Co čekat', headlineBottom: 'od úloh?' },
                subpages: [],
            }, {
                pathName: 'jak_na_to',
                group: AccessGroup.All,
                page: JakNaTo,
                meta: { title: 'Jak se řeší úloha?', headlineTop: 'Jak se řeší', headlineBottom: 'úloha?' },
                subpages: [],
            }, {
                pathName: 'vstup_vystup',
                group: AccessGroup.All,
                page: VstupVystup,
                subpages: [],
                meta: {
                    title: 'Vstup a výstup',
                    headlineTop: 'Jak snadno zpracovat',
                    headlineBottom: 'vstup a výstup?',
                },
            }, {
                pathName: 'modulo',
                group: AccessGroup.All,
                page: Modulo,
                meta: { title: 'Modulo', headlineTop: 'Na co se hodí', headlineBottom: 'modulo?' },
                subpages: [],
            }, {
                pathName: 'retezce',
                group: AccessGroup.All,
                page: Retezce,
                meta: { title: 'Textové řetězce', headlineTop: 'Jak snadno pracovat', headlineBottom: 's řetězci?' },
                subpages: [],
            }, {
                pathName: 'trideni',
                group: AccessGroup.All,
                page: Trideni,
                subpages: [],
                meta: { title: 'Třídění', headlineTop: 'Co je vlastně', headlineBottom: 'třídění?' },
            }, {
                pathName: 'grafy',
                group: AccessGroup.All,
                page: Grafy,
                subpages: [],
                meta: { title: 'Grafy', headlineTop: 'Úvod', headlineBottom: 'do teorie grafů' },
            }, {
                pathName: 'dynamicke_programovani',
                group: AccessGroup.All,
                page: DynamickeProgramovani,
                meta: {
                    title: 'Dynamické programování',
                    headlineTop: 'Co je to',
                    headlineBottom: 'dynamické programování?',
                },
                subpages: [],
            }, {
                pathName: 'slozitost',
                group: AccessGroup.All,
                page: Slozitost,
                meta: {
                    title: 'Složitost',
                    headlineTop: 'Předběhne můj program zajíce?',
                    headlineBottom: 'Nebo alespoň želvu?',
                },
                subpages: [],
            }],
        }, {
            pathName: 'pro_ucitele',
            group: AccessGroup.All,
            page: ProUcitele,
            meta: { title: 'Pro učitele', headlineTop: 'Co dělat', headlineBottom: 've škole?' },
            subpages: [],
        }, {
            pathName: 'org',
            redirect: 'org/info',
            group: AccessGroup.Orgs,
            page: ErrorPage, // unused due to redirect
            meta: { title: 'Org', headlineTop: 'Nástroje pro', headlineBottom: 'organizátory' },
            subpages: [{
                pathName: 'info',
                group: AccessGroup.Orgs,
                page: OrgPage,
                meta: { title: 'Org', headlineTop: 'Nástroje pro', headlineBottom: 'organizátory' },
                subpages: [],
            }, {
                pathName: 'users',
                group: AccessGroup.Orgs,
                page: UsersPage,
                meta: { title: 'Uživatelé', headlineTop: 'Přehled', headlineBottom: 'uživatelů' },
                subpages: [],
            }],
        }, {
            pathName: 'user',
            redirect: 'user/settings',
            group: AccessGroup.LoggedIn,
            page: ErrorPage, // unused due to redirect
            meta: { title: 'Nastavení', headlineTop: 'Nastavení', headlineBottom: 'uživatele' },
            subpages: [{
                pathName: 'settings',
                group: AccessGroup.LoggedIn,
                page: UserPage,
                meta: { title: 'Nastavení', headlineTop: 'Nastavení', headlineBottom: 'uživatele' },
                subpages: [],
            }, {
                pathName: 'password',
                redirect: '/reset-password',
                group: AccessGroup.LoggedIn,
                page: ErrorPage, // unused due to redirect
                meta: { title: 'Změna hesla', headlineTop: 'Změna', headlineBottom: 'hesla' },
                subpages: [],
            }],
        },
    ];
    const extraRoutes = promised[0][1].concat(promised[1][1]);
    // redirects for tutorials (to keep the links from old web working)
    extraRoutes.push({
        path: '/tipytriky/druhy_uloh.html',
        redirect: '/tipytriky/druhy_uloh/',
    }, {
        path: '/tipytriky/jak_na_to.html',
        redirect: '/tipytriky/jak_na_to/',
    }, {
        path: '/tipytriky/vstup.html',
        redirect: '/tipytriky/vstup_vystup/',
    }, {
        path: '/tipytriky/retezce.html',
        redirect: '/tipytriky/retezce/',
    }, {
        path: '/tipytriky/trideni.html',
        redirect: '/tipytriky/trideni/',
    }, {
        path: '/tipytriky/grafy.html',
        redirect: '/tipytriky/grafy/',
    }, {
        path: '/tipytriky/dynamicke_programovani.html',
        redirect: '/tipytriky/dynamicke_programovani/',
    }, {
        path: '/tipytriky/slozitost.html',
        redirect: '/tipytriky/slozitost/',
    });
    // routes for email verification
    extraRoutes.push({
        path: '/verify',
        component: VerifyEmailPage,
        meta: { title: 'Ověření emailu', headlineTop: 'Ověření', headlineBottom: 'emailu' },
    }, {
        path: '/verify/:verificationCode',
        component: VerifyEmailPage,
        meta: { title: 'Ověření emailu', headlineTop: 'Ověření', headlineBottom: 'emailu' },
    });
    extraRoutes.push({
        path: '/reset-password',
        component: PasswordResetPage,
        meta: { title: 'Reset hesla', headlineTop: 'Obnovení', headlineBottom: 'hesla' },
    }, {
        path: '/reset/:token',
        component: NewPasswordPage,
        meta: { title: 'Reset hesla', headlineTop: 'Obnovení', headlineBottom: 'hesla' },
    });
    // unauthorized page
    extraRoutes.push({
        path: '/unauthorized',
        component: UnauthorizedPage,
        meta: { title: 'Chyba', headlineTop: 'Sem nemáš', headlineBottom: 'přístup' },
    });
    // for debugging Sentry
    extraRoutes.push({
        path: '/error',
        component: ErrorPage,
        meta: { title: 'Chybová stránka', headlineTop: 'Chybová', headlineBottom: 'stránka' },
    });
    extraRoutes.push({
        path: '/org/users/:id',
        component: UserDetailsPage,
        meta: { title: 'Uživatel', headlineTop: 'Detail', headlineBottom: 'uživatele' },
    });

    return [stromecek, extraRoutes];
}

const sponsors = [
    {
        name: 'Wube',
        img: assetsImageURL('sponsors/wube-logo-negative.png'),
        link: 'https://www.factorio.com/game/about',
    },
    {
        name: 'JetBrains Foundation',
        img: assetsImageURL('sponsors/jetbrains-foundation.svg'),
        link: 'https://www.jetbrains.com/',
    },
    {
        name: 'CZ.NIC',
        img: assetsImageURL('sponsors/cz-nic_logo.svg'),
        link: 'https://www.nic.cz/',
    },
    {
        name: 'Prusa Research',
        img: assetsImageURL('sponsors/prusaresearch-logo-rgb-black.png'),
        link: 'https://www.prusa3d.com/',
    },
    {
        name: 'Wincent',
        img: assetsImageURL('sponsors/wincent.png'),
        link: 'https://www.wincent.com/',
    },
];

const FILE_SIZE_LIMIT = 100000000; // don't allow submitting files larger 100 MB

export {
    getStromecek, sponsors, FILE_SIZE_LIMIT,
};
