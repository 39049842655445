import { User } from '@/types';
import apiService from './ApiService';
import jwtService from '@/api/JwtService';

class UserService {
    async getUser(userId: number): Promise<User | null> {
        return apiService.get(`users/${userId}`);
    }

    async getCurrentUser(): Promise<User | null> {
        const userId = jwtService.getUserId();
        if (userId === null)
            return null;
        return await this.getUser(userId);
    }

    async postUserSpam(userId: number, allowSpam: boolean): Promise<boolean> {
        const result = await apiService.post(`users/${userId}/spam`, String(allowSpam),
            { headers: { 'Content-Type': 'application/json' } }, // we need to set the content type explcitly
            () => true); // empty error handler
        return result !== null; // successful request returns empty string, unsuccessful returns null
    }
}

export default new UserService();
