import { AxiosError } from 'axios';
import { LoginParams, LoginResponse } from '@/types';
import apiService, { AuthHeaderName } from './ApiService';
import jwtService from './JwtService';

type RegisterParams = {
    firstName: string,
    lastName: string,
    mffSpam: boolean,
    email: string,
    password: string,
    knowsFrom: string,
    otherGigs: string,
};

const isStatus401ErrorHandler = (error: AxiosError): boolean => error.response?.status === 401;
const isWrongTokenErrorHandler = (error: AxiosError): boolean =>
    error.response?.status === 403 && error.response.data === 'WRONG_TOKEN';

class AuthService {

    async login(apiCredentials: LoginParams, errorHandler?: (error: AxiosError) => boolean)
    : Promise<boolean> {
        const data: LoginResponse | null = await apiService
            .post('auth', apiCredentials, undefined, errorHandler, false);
        if (data == null) return false;
        // save the token
        apiService.setHeaderAndSaveToken(data.access_token);
        return true;
    }

    async register({
        email, firstName, knowsFrom, lastName, mffSpam, otherGigs, password,
    }: RegisterParams, errorHandler?: (error: AxiosError) => boolean): Promise<number | null> {
        const payload = {
            email,
            knows_from: knowsFrom,
            first_name: firstName,
            password,
            last_name: lastName,
            mff_spam: mffSpam,
            other_gigs: otherGigs,
        };
        return apiService.post('auth/register', payload, undefined, errorHandler, false);
    }

    /** Tries to log in using data saved in cookies. */
    async refreshLogin(): Promise<boolean> {
        return apiService.refresh();
    }

    async logout(): Promise<void> {
        // try to log out, ignore 401 errors
        await apiService.post('auth/logout', undefined, undefined, isStatus401ErrorHandler, false);
        // delete the token
        jwtService.deleteToken();
        apiService.unsetHeader();
    }

    async verify(verificationToken: string): Promise<boolean> {
        const response = await apiService.post('auth/verify', { }, {
            headers: { [AuthHeaderName]: `Bearer ${verificationToken}` },
        }, isWrongTokenErrorHandler, false);
        /* If the verification code is correct, the API (and thus also `apiService.post`) returns empty body.
           If an error occurs during the request, the `isWrongTokenErrorHandler` is called and `null` is returned from
           `apiService.post`. */
        return response === '';
    }

    async resetPassword(email: string): Promise<boolean> {
        const response = await apiService.post('auth/reset_password', `"${email}"`, {
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
        }, isStatus401ErrorHandler, false); // 401 if email doesn't exist
        return response === ''; // API returns empty body; if an error occurs during request, `null` is returned
    }

    async changePassword(verificationToken: string, newPassword: string): Promise<boolean> {
        const response = await apiService.post('auth/change_password', { new_password: newPassword }, {
            headers: { [AuthHeaderName]: `Bearer ${verificationToken}` },
        }, isWrongTokenErrorHandler, false);
        /* If the verification code is correct, the API (and thus also `apiService.post`) returns empty body.
           If an error occurs during the request, the `isWrongTokenErrorHandler` is called and `null` is returned from
           `apiService.post`. */
        return response === '';
    }
}

export default new AuthService();
