/* eslint-disable camelcase */
import { Component } from 'vue';

export enum AccessGroup {
  All,
  Competitors,
  Orgs,
  LoggedIn,
}

type HeaderInfo = {
    headerStyle?: 'small',
    headlineTop: string,
    headlineBottom: string,
} | {
    headerStyle: 'landing'
};

export type RouteMeta = {
    title: string,
    noSubMenu?: boolean,
    pageTitle?: string
} & HeaderInfo;

export interface MenuItem<T> {
    pathName?: string;
    group: AccessGroup;
    page: Component;
    pageProps?: Record<string, unknown>;
    /** Redirect specifies a new route. If it starts with "/", it is absolute path (from web root),
      * otherwise its relative.
      * If the redirect is set, the page field is ignored.
      */
    redirect?: string,
    meta: RouteMeta;
    subpages: T[];
}

export type GeneratedMenu = GeneratedItem;

export interface DesignItem extends MenuItem<DesignItem> {
    path?: string;
}

export interface GeneratedItem extends MenuItem<GeneratedItem> {
    path: string;
}

// DesignMenu - optional path, optional pathName
// GeneratedMenu - required path, optional pathName

export type LoginParams = { email: string, password: string };
export type LoginResponse = { access_token: string, access_token_expiry: KasiopeaTimestamp };

/**
 * All data for displaying results that need
 * to be retrieved from backend
 */
export interface Scoreboard {
    task_letters: string;
    contestants: ScoreboardRow[];
}

/**
 * One entry in a round results table
 */
export interface ScoreboardRow {
    name: string;
    /** taskLetter => points */
    map_points: Record<string, number>;
    minutes: number;
    total_points: number;
}

export class InvalidRoundError extends Error {}

// TODO mělo by být něco jako: UTCTimestring($yyyy-mm-ddThh:MM:ssZ)
export type KasiopeaTimestamp = string;

export enum CourseState {
    INVALID = 'invalid',
    NOT_STARTED = 'not_started',
    ACTIVE = 'active',
    ACTIVE_FROZEN = 'active_frozen',
    FINISHED_FROZEN = 'finished_frozen',
    FINISHED = 'finished',
}

export enum CourseKind {
    HOME = 'home',
    FINAL = 'final',
    PUBLIC_TESTING = 'public_testing',
}

export function getCzechCourseKind(kind: CourseKind): string {
    switch (kind) {
    case CourseKind.HOME:
        return 'Domácí kolo';
    case CourseKind.FINAL:
        return 'Finále';
    case CourseKind.PUBLIC_TESTING:
        return 'Testování';
    default:
        return 'neznámý druh kola';
    }
}

export function getCourseKindForURL(kind: CourseKind): string {
    switch (kind) {
    case CourseKind.HOME:
        return 'doma';
    case CourseKind.FINAL:
        return 'finale';
    default:
        return kind;
    }
}

export interface Course {
    state: CourseState;
    kind: CourseKind;
    tasks: TaskInfo[];
    year: number;
    start_time: KasiopeaTimestamp;
    unfreeze_time: KasiopeaTimestamp;
    end_time: KasiopeaTimestamp;
    id: number;
    freeze_time: KasiopeaTimestamp;
    contestant_id: number | null;
}

export enum TaskDifficulty {
    EASY = 'easy',
    HARD = 'hard',
}

/**
 * Task (as returned from backend)
 */
export interface Task {
    id: number;
    name: string;
    course_id: number;
    attempts: number[];
    subtasks: Subtask[];
}

/**
 * Subtask (as returned from backend)
 */
export interface Subtask {
    requires_source: boolean;
    difficulty: TaskDifficulty;
    received_points: number;
    maximum_points: number;
    first_successful_attempt: boolean;
}

export interface TaskInfo {
    id: number;
    name: string;
    letter: string;
}

export interface Attempt {
    id: string,
    task_id: number,
    contestant_id: number,
    difficulty: TaskDifficulty,
    input_valid_until: KasiopeaTimestamp,
    submitted_on: KasiopeaTimestamp,
    state: AttemptState,
    received_points: number,
}

export enum AttemptState {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILURE = 'failure',
    TIMEOUT = 'timeout',
    INTERNAL_ERROR = 'internal_error',
}

/**
 * User (as returned from backend)
 */
export interface User {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    verified: boolean;
    last_login: KasiopeaTimestamp;
    last_refresh: KasiopeaTimestamp;
    courses: number[];
    role: UserRole;
    mff_spam: boolean;
}

export enum UserRole {
    ORG = 'org',
    CONTESTANT = 'contestant',
}

/**
 * LandingTitle (as returned from backend)
 */
export interface LandingTitle {
    top_text: string,
    bottom_text: string,
}

export enum Gender {
    MALE, FEMALE
}

export interface Medailonek {
    name: string;
    surname: string;
    img: string;
    text: string;
    started: number;
    ended?: number;
    study?: string;
    studyFinished?: boolean;
    link?: string;
    github?: string;
    gender: Gender;
}

export interface VuePictureSwipeItem {
    src: string,
    thumbnail: string,
    /** If you don't want use width and heigt, just use w: 0 and h: 0 */
    w: number,
    h: number,
    alt?: string,
    id: string,
}
