import { createStore } from 'vuex';

// define your typings for the store state
export interface State {
  /** If user is currently logged in */
  isLoggedIn: boolean;
  /** If the current user is org */
  isOrg: boolean;
  /** Modal for registration is open */
  registrationModalOpen: boolean,
}

export const store = createStore<State>({
    state() {
        return {
            isLoggedIn: false,
            isOrg: false,
            registrationModalOpen: false,
        };
    },
    mutations: {
        login(state) {
            state.isLoggedIn = true;
        },
        loginOrg(state) {
            state.isLoggedIn = true;
            state.isOrg = true;
        },
        logout(state) {
            state.isLoggedIn = false;
            state.isOrg = false;
        },
        openRegistrationModal(state) {
            state.registrationModalOpen = true;
        },
        closeRegistrationModal(state) {
            state.registrationModalOpen = false;
        },
    },
});
