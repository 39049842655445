<template>
    <!-- type="dark" is not yet implemented in bootstrap-vue-next, we use data-bs-theme="dark" to fix it -->
    <b-navbar toggleable="md" type="dark" class="navbar px-3" :container="false" data-bs-theme="dark">
        <b-navbar-brand to="/">
            <img alt="Logo" src="@/assets/logo.svg"/>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"/>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="nav-item-container">
                <b-nav pills>
                    <router-link v-for="page in menu" :key="page.path" v-slot="{ navigate }" :to="page.path" custom>
                        <BNavItem class="nav-item" :active="$route.path.startsWith(page.path)" @click="navigate">
                            {{ page.meta.title }}
                        </BNavItem>
                    </router-link>
                </b-nav>
            </b-navbar-nav>
            <div class="login_buttons">
                <div>
                    <Login/>
                </div>
                <div>
                    <RegisterButton/>
                </div>
            </div>
        </b-collapse>
    </b-navbar>
</template>

<script lang="ts">
import { GeneratedMenu } from '@/types';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    name: 'MainMenu',
    props: {
        menu: {
            type: Array as PropType<GeneratedMenu[]>,
            default() {
                return [];
            },
        },
    },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/styles/variables.scss';

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .navbar {
        justify-content: center;
    }

    .navbar :deep(*) {
        flex-grow: 0;
    }
}

.navbar {
    background-color: $purple_dark;
}

.nav-item-container {
    list-style-type: none;
    padding: 0;
}

.navbar-nav {
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.navbar-expand-md:deep(.navbar-nav .nav-link) {
    padding: 8px 12px;
}

.navbar-nav .nav-item:deep(.nav-link) {
    color: $purple_light;
    font-weight: bold;
    transition: all 0.3s ease 0s;
}

.navbar-nav .nav-item:deep(.nav-link.active) {
    color: $purple_highlight_light;
    background-color: $purple_highlight;
    border-radius: 10px;
}

.login_buttons>div {
    margin-left: 10px;
    float: left;
}

.login_buttons {
    flex-basis: auto;
    flex-shrink: 0;
}

/* Small devices (767px and below) */
@media (max-width: 767px) {
    .navbar-nav {
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 400px;
        margin: 0px auto;
    }

    .nav-item {
        flex-basis: 50%;
    }

    .nav-item-container {
        margin: 10px auto;
    }

    .login_buttons {
        max-width: 400px;
        margin: 0px auto;
    }

    .login_buttons>div {
        width: 50%;
        margin-left: 0px;
        padding-left: 12px;
    }

    .navbar {
        padding-bottom: 1rem;
    }
}
</style>
