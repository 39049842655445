import { RouteRecordRaw } from 'vue-router';
import {
    AccessGroup, Course, CourseKind, DesignItem, GeneratedItem, GeneratedMenu,
    getCourseKindForURL, getCzechCourseKind, TaskInfo,
} from '@/types';
import courseService from './api/CourseService';
import { store } from './store';

const TaskPage = () => import('@/components/pages/TaskPage.vue');
const SolutionPage = () => import('@/components/pages/SolutionPage.vue');
const CoursePage = () => import('@/components/pages/CoursePage.vue');
const EmptyPage = () => import('@/components/pages/EmptyPage.vue');
const CompetitionPage = () => import('@/components/pages/soutez/CompetitionPage.vue');
const CompetitionFinalPage = () => import('@/components/pages/soutez/CompetitionFinalPage.vue');
const NoCompetitionPage = () => import('@/components/pages/soutez/NoCompetitionPage.vue');
const ResultsPage = () => import('@/components/pages/soutez/ResultsPage.vue');

/** If @param inputMenuItem contains a redirect, this fucntion will generate an absolute path from it. */
function getRedirectForMenuItem(inputMenuItem: DesignItem, path: string): string | undefined {
    if (inputMenuItem.redirect) {
        // absolute redirect (path from web root)
        if (inputMenuItem.redirect.startsWith('/')) return inputMenuItem.redirect;
        // relative redirect path
        return `${path}${inputMenuItem.redirect}/`;
    }
    return undefined;
}

/**
 * Generates multi-level menu from design items.
 * Returns GeneratedMenu (adjusted for linking - see notes in types).
 *
 * @param inputMenu design items - defined by user, internally aliased as 'stromecek'
 * @param path optional path parameter for customizing prepath for items in menu (used especially for recurse menu
 *  generating), default /
 */
function generateMenuFrom(inputMenu: DesignItem[], path = '/'): GeneratedMenu[] {
    const outputMenu: GeneratedMenu[] = [];
    (inputMenu || []).forEach((inputMenuItem: DesignItem) => {
        const itemPath = inputMenuItem.path ? inputMenuItem.path : `${path}${inputMenuItem.pathName}/`;
        const redirect = getRedirectForMenuItem(inputMenuItem, path);
        const outputMenuItem = {
            group: inputMenuItem.group,
            path: itemPath,
            page: inputMenuItem.page,
            pageProps: inputMenuItem.pageProps,
            meta: inputMenuItem.meta,
            redirect, // redirect might be intentionally undefined
            subpages: generateMenuFrom(inputMenuItem.subpages, itemPath),
        };
        outputMenu.push(outputMenuItem);
    });
    return outputMenu;
}

/**
 * Checks whether user has access to a page based on AccessGroup
 *
 * @param menuItem generated menu item
 */
function allowAccess(menuItem: GeneratedItem): boolean {
    if (menuItem.group === AccessGroup.Orgs) {
        return store.state.isOrg;
    }
    if (menuItem.group === AccessGroup.LoggedIn) {
        return store.state.isLoggedIn;
    }
    return true;
}

/**
 * Generates routes for Vue Router from generated menu.
 *
 * @param menu generated menu (or created menu by user with using GeneratedMenu type)
 * @param prepath optional prepath - default /
 */
function generateRoutesFromMenu(menu: GeneratedMenu[], prepath = '/'): RouteRecordRaw[] {
    const routes: RouteRecordRaw[] = [];

    menu.forEach((menuItem) => {
        if (menuItem.redirect !== undefined) {
            routes.push({
                path: `${menuItem.path}`,
                redirect: menuItem.redirect,
            });
        } else {
            routes.push({
                path: `${menuItem.path}`,
                component: menuItem.page,
                props: menuItem.pageProps || {},
                meta: menuItem.meta,
                beforeEnter: (_from: any, _to: any, next: any) => {
                    if (!allowAccess(menuItem)) {
                        next('/unauthorized/');
                    } else {
                        next();
                    }
                },
            });
        }
        routes.push(...generateRoutesFromMenu(menuItem.subpages, `${prepath}${menuItem.path}/`));
    });

    return routes;
}

/**
 * Generated menu with tasks for specified course.
 * @param course from which will be generated menu with tasks
 */
async function generateMenuForCourseTasks(course: Course, prepath: string): Promise<[DesignItem[], RouteRecordRaw[]]> {
    if (!course) return [[], []];
    const taskItems = await Promise.all(
        course.tasks.map(async(task: TaskInfo): Promise<[DesignItem, RouteRecordRaw[]]> => [{
            pathName: `${task.letter}`,
            group: AccessGroup.All,
            page: TaskPage,
            pageProps: { taskId: task.id, taskLetter: task.letter, course },
            meta: {
                title: `${task.letter}: ${task.name}`,
                headlineTop: getCzechCourseKind(course.kind),
                headlineBottom: `${task.letter}: ${task.name}`,
            },
            subpages: [],
        }, [{
            path: `${prepath}/${task.letter}/reseni/`,
            component: SolutionPage,
            props: { taskId: task.id },
            meta: {
                title: `${task.letter}: ${task.name}`,
                headlineTop: getCzechCourseKind(course.kind),
                headlineBottom: `${task.letter}: ${task.name}`,
            },
        }, {
            path: `${prepath}/${task.letter}/reseni.html`,
            redirect: `${prepath}/${task.letter}/reseni/`,
        }]]),
    );
    const menuItems = taskItems.map((t) => t[0]);
    const extraRoutes = taskItems.flatMap((t) => t[1]);
    return [menuItems, extraRoutes];
}

/**
 * Generate menu for courses of specified year. Generated courses contains
 * also generated tasks via @method generateMenuForCourseTasks
 * @param year from which will be generated menu with courses
 * @param loadedCourses generate menu from specified course, if courses not specified, fetch courses
 * from BE for specified year
 */
async function generateMenuForCourseYear(year: number, loadedCourses: Course[] | null = null, prepath: string)
: Promise<[DesignItem[], RouteRecordRaw[]]> {

    const courses: Course[] | null = loadedCourses || (await courseService.getAllCoursesFromYear(year));

    const courseYearMenuAndRoutes = await Promise.all(
        (courses || []).map(async(course: Course): Promise<[DesignItem, RouteRecordRaw[]]> => {
            const [menuItems, extraRoutes] = await generateMenuForCourseTasks(course,
                `${prepath}/${year}/${getCourseKindForURL(course.kind)}`);
            return [{
                pathName: getCourseKindForURL(course.kind),
                group: AccessGroup.All,
                page: CoursePage,
                pageProps: { course },
                meta: {
                    title: getCzechCourseKind(course.kind),
                    headlineTop: `Ročník ${year}`,
                    headlineBottom: getCzechCourseKind(course.kind),
                },
                subpages: [
                    {
                        pathName: 'vysledky',
                        group: AccessGroup.All,
                        page: ResultsPage,
                        pageProps: { course },
                        meta: {
                            title: 'Výsledky',
                            headlineTop: getCzechCourseKind(course.kind),
                            headlineBottom: 'Výsledky',
                        },
                        subpages: [],
                    },
                    ...menuItems,
                ],
            }, extraRoutes];
        }),
    );
    const menuItems = courseYearMenuAndRoutes.map((t) => t[0]);
    const extraRoutes = courseYearMenuAndRoutes.flatMap((t) => t[1]);
    return [menuItems, extraRoutes];
}

/**
 * Generate menu (with design items) for "archiv úloh", using generateMenuForCourseYear.
 * Contains only courses which has got unfreeze time < now while is menu generating.
 */
async function generateArchiv(courses: Course[]): Promise<[DesignItem[], RouteRecordRaw[]]> {
    const now = new Date();
    // This reducer create object which has got arrays of courses for each year separeted
    // The reducer also filter non-archiv courses
    /* eslint-disable no-param-reassign */
    // recuder want reassign param by nature
    const coursesByYear: Record<number, Course[]> = courses.reduce(
        (accumulator: Record<number, Course[]>, current: Course) => {
            if (new Date(current.unfreeze_time) < now) {
                if (!accumulator[current.year]) accumulator[current.year] = [];
                accumulator[current.year].push(current);
            }
            return accumulator;
        }, {},
    );
    /* eslint-enable no-param-reassign */

    const years = Object.keys(coursesByYear).sort();
    const result = await Promise.all(years.map(async(yearString): Promise<[DesignItem, RouteRecordRaw[]]> => {
        const year: number = Number.parseInt(yearString, 10);
        const [menuItems, extraRoutes] = await generateMenuForCourseYear(year, coursesByYear[year], '/archiv');
        return [{
            group: AccessGroup.All,
            page: EmptyPage,
            pathName: yearString,
            meta: { title: yearString, headlineTop: 'Soutěž', headlineBottom: `${year}/${year + 1}` },
            subpages: menuItems,
        }, extraRoutes];
    }));
    const menuItems = result.map((t) => t[0]);
    const extraRoutes = result.flatMap((t) => t[1]);
    return [menuItems, extraRoutes];
}

async function generateSoutez(course: Course | undefined, lastPastCourse: Course | undefined)
: Promise<[DesignItem, RouteRecordRaw[]]> {
    if (course) {
        const competitionMenu = await generateMenuForCourseTasks(course, '/soutez');
        return [{
            pathName: 'soutez',
            group: AccessGroup.All,
            page: EmptyPage, // unused due to redirect
            meta: { title: 'Soutěž', headlineTop: getCzechCourseKind(course.kind), headlineBottom: 'Úvod' },
            redirect: 'soutez/uvod',
            subpages: [
                {
                    pathName: 'uvod',
                    group: AccessGroup.All,
                    page: course.kind === CourseKind.FINAL ? CompetitionFinalPage : CompetitionPage,
                    pageProps: { course },
                    meta: { title: 'Úvod', headlineTop: getCzechCourseKind(course.kind), headlineBottom: 'Úvod' },
                    subpages: [],
                },
                {
                    pathName: 'vysledky',
                    group: AccessGroup.All,
                    page: ResultsPage,
                    pageProps: { course },
                    meta: {
                        title: 'Výsledky',
                        headlineTop: getCzechCourseKind(course.kind),
                        headlineBottom: 'Výsledky',
                    },
                    subpages: [],
                },
                ...competitionMenu[0],
            ],
        },
        competitionMenu[1]];
    }
    return [{
        pathName: 'soutez',
        group: AccessGroup.All,
        page: NoCompetitionPage,
        pageProps: { course: lastPastCourse },
        meta: { title: 'Soutěž', headlineTop: 'Soutěž', headlineBottom: 'neprobíhá' },
        subpages: [],
    }, [{
        path: `/soutez/*`,
        redirect: `/soutez`,
    }]];
}

export {
    generateRoutesFromMenu,
    generateMenuFrom,
    generateSoutez,
    generateArchiv,
    allowAccess,
};
